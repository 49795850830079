import meme1 from "../../Assets/images/meme1.webp";
import meme4 from "../../Assets/images/meme4.jpg";
import meme5 from "../../Assets/images/meme5.png";
import meme6 from "../../Assets/images/meme6.jpeg";

export const carouselItem = [
  {
    altText: "",
    caption: "",
    key: 1,
    src: meme1,
  },
  {
    altText: "",
    caption: "",
    key: 2,
    src: meme4,
  },
  {
    altText: "",
    caption: "",
    key: 3,
    src: meme5,
  },
  {
    altText: "",
    caption: "",
    key: 5,
    src: meme6,
  },
];
