export const headerItem = [
  {
    title: "Preface",
    id: "preface",
  },
  {
    title: "Skills",
    id: "skills",
  },
  {
    title: "Contact",
    id: "contact",
  },
];
